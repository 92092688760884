import React from "react"
const Video = ({ videoSrcURL, videoTitle, videoRef, ...props }) => (
  <div className="proj-gameplay">
    <video
      src={videoSrcURL}
      title={videoTitle}
      ref={videoRef}
      autoplay="autoplay"
      loop
      muted
      defaultmuted
      playsInline
      preload="auto"
      controls
      controlsList="nodownload"
      width="90%"
      height="auto"
      className="videoplayer"
    />
  </div>
)
export default Video
