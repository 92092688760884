/* eslint-disable */
import React, { useRef, useState, useEffect, useCallback } from "react"
import SEO from "../components/seo"
import LoadingScreen from "../components/LoadingScreen/LoadingScreen.component"
import Header from "../components/UI/Header.component"
import Sidenav from "../components/UI/Sidenav.component"
import Sidenav2 from "../components/UI/Sidenav2.component"
import Sidenav_mb from "../components/UI/Sidenav_mb.component"
import SelfIntro from "../components/SelfIntro.component"
import WhatWeDo from "../components/WhatWeDo.component"
import Cover from "../components/Cover.component"
import HowWeDo from "../components/HowWeDo.component"
import CaseStudies from "../components/CaseStudies.component"
import Platform from "../components/Platform.component"
import ContactUs from "../components/ContactUs.component"
import Navs from "../components/UI/Navs.component"
import Legal from "../components/UI/Legal.component"

import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll"

import { Waypoint } from "react-waypoint"
import VisibilitySensor from "react-visibility-sensor"

var opa = 0
var test = 0
var test1 = 1
const Index = ProjectsAct => {
  // useRef instead of querySelector (commonly used in vanilla JS + three.js projects)

  const [loading, setLoading] = useState(false)
  const [CoverMenuVisible, setCoverVisible] = useState(true)
  const [ContentMenuVisible, setContentVisible] = useState(false)

  const VisibleCoverMenu = () => {
    setCoverVisible(true)
    setContentVisible(false)
  }

  const inVisibleCoverMenu = () => {
    setCoverVisible(false)
  }

  const VisibleContentMenu = () => {
    setContentVisible(true)
    setCoverVisible(false)
  }

  const inVisibleContentMenu = () => {
    setContentVisible(false)
  }

  const InvisibleMenu = () => {
    setContentVisible(false)
    setCoverVisible(false)
  }

  const checkthis = () => {
    document.getElementById("foo4").style.color = "#ffe200"
    document.getElementById("foo5").style.color = "#dadada"
  }

  const leavethis = () => {
    document.getElementById("foo5").style.color = "#ffe200"
    document.getElementById("foo4").style.color = "#dadada"
  }

  const checkthis2 = () => {
    document.getElementById("foo5").style.color = "#ffe200"
  }

  const leavethis2 = () => {
    document.getElementById("foo5").style.color = "#dadada"
  }

  const checkthisshit2 = isVisible => {
    if (isVisible) {
      checkthis
    } else {
      leavethis
    }
  }
  return (
    <>
      <SEO title="Luxurious and stunning archviz" />

      <LoadingScreen loading={loading} />

      {!loading && (
        <main>
          <Header />
          <Waypoint
            onEnter={VisibleCoverMenu}
            //onLeave={inVisibleCoverMenu}
          />
          {CoverMenuVisible && <Sidenav />}
          {ContentMenuVisible && <Sidenav2 />}
          <Sidenav_mb />

          <Cover />

          <Element name="AboutUs" className="element">
            <SelfIntro />
          </Element>

          <Element name="WhatWeDo" className="element2">
            <WhatWeDo />
          </Element>

          <Waypoint topOffset="1080px" onEnter={VisibleContentMenu} />

          <Element name="HowWeDo" className="element">
            <HowWeDo />
          </Element>
          <Element name="CaseStudies" className="element">
            <CaseStudies />
          </Element>

          <Element name="Platform" className="element">
            <Platform />
          </Element>
          <Waypoint onEnter={checkthis2} onLeave={leavethis2} />

          <Element name="ContactUs" className="element element2">
            <ContactUs />
            <Legal />
            <Waypoint topOffset={400} onEnter={checkthis} onLeave={leavethis} />
            <VisibilitySensor onChange={checkthisshit2}>
              <div className="visiblesensor2"> </div>
            </VisibilitySensor>{" "}
          </Element>
        </main>
      )}
    </>
  )
}

export default Index
