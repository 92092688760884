import React, { useState } from "react"
import Tabletop from "../images/CoreProduct/tabletop.svg"
import VirtualTour from "../images/CoreProduct/virtualtour.svg"
import Walkthrough from "../images/CoreProduct/walkthrough.svg"
import Animation from "../images/CoreProduct/animation.svg"
import Render from "../images/CoreProduct/render.svg"
import CoreProductImage from "../images/CoreProduct/core-product-image.png"
import Cross from "../images/Cross.svg"
import Modal from "react-bootstrap/Modal"
import { motion } from "framer-motion"
import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Collapse from "react-bootstrap/Collapse"

const CoreProduct = () => {
  const [show, setShow] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [index, setIndex] = useState(0)
  const [expand, setExpand] = useState(false)
  const [expandmb, setExpandmb] = useState(false)
  const [expandmb2, setExpandmb2] = useState(false)
  const [mobile, setMobile] = useState(false)
  const handleClose = () => {
    setShow(false)
    setExpand(false)
    setExpandmb(false)
    setExpandmb2(false)
    setMobile(false)
  }

  const handleShow = () => {
    setIndex(0)
    if (window.innerWidth < 680) {
      setExpandmb(true)
      setMobile(true)
    } else {
      setExpand(true)
      setMobile(false)
    }
  }

  const handleShow1 = () => {
    setIndex(1)
    setMobile(false)

    setExpand(true)
  }

  const duration = 2000

  const [fullscreen, setFullscreen] = useState(true)
  const data = [
    {
      text1:
        "The Tabletop is our flagship interactive experience available in VR, touch panels or desktop devices.",
      text2:
        "Designed for medium to large scale architectural developments, it makes complex or abstract information visible and tangible.",
      text3:
        "Modular and expandable, the project can decide what and how to present its key ideas and sells - only use the tabletop view for a macro overview or combine it with an interactive virtual tour to get into the micro details.",
      text4:
        "Customization options also available to ensure the resulting application enhances existing branding and pushes marketing goals.",
    },
    {
      text1:
        "In addition to interactive applications and experiences, we also offer services in traditional media - namely rendered images and animations.",
      text2:
        "Leveraging the latest realtime display technologies, we are able to deliver the highest quality media for your project at a scale that was never before possible.",
      text3: "",
      text4: "",
    },
  ]
  const Overlay = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    zindex: 9999;
    z-index: 9999;
  `

  const divHeight = [400, 250]

  const aVariant = {
    initial: { y: "-100%" },
    isOpen: { y: "0" },
    exit: { y: "-100%" },
  }

  const modalVariant = {
    initial: { opacity: 1, transition: { delay: 0.1 } },
    isOpen: { opacity: 1, transition: { delay: 1 } },
    exit: { opacity: 1, transition: { delay: 0.1 } },
  }
  const containerVariant = {
    initial: {
      height: "1%",
      paddingTop: 0,
      paddingBottom: 0,
      transition: { type: "spring" },
    },
    isOpen: { height: "1%", paddingTop: 77, paddingBottom: 77 },
    exit: { height: "1%", paddingTop: 0, paddingBottom: 0 },
  }

  return (
    <section id="WhatWeDo">
      <Modal
        closeTimeoutMS={2000}
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-feature"
      >
        <div className="feature-background">
          <div className="feature-container">
            <div className="feature-font1">
              <div> FEATURE </div>
            </div>

            <div className="feature-font2">
              {data[index].text1}
              <br />
              <br />
              {data[index].text2}
              <br />
              <br />
              {data[index].text3}
              <br />
              <br />
              {data[index].text4}
            </div>
            <div className="Dock-X" onClick={handleClose}>
              <img src={Cross} className="CrossIcon2" />
            </div>
          </div>
        </div>
      </Modal>
      <div className="CoreProduct-background">
        <div className="CoreProduct-Container">
          <div
            className={"CoreProduct" + (mobile === true ? "-mb" : "")}
            id="foo2"
          >
            <div className="CoreProduct-Fonts">/ WHAT WE DO</div>

            <div className="CoreProduct-font2">CORE PRODUCT</div>
          </div>
          <div
            className={
              "CoreProduct-ImgContainer" + (mobile === true ? "-mb" : "")
            }
          >
            <LazyLoadImage
              effect="blur"
              src={CoreProductImage}
              placeholderSrc={CoreProductImage}
              alt={CoreProductImage}
              className="CoreProduct-Image"
            />
          </div>

          <div className="CoreProduct-Products">
            <div className="CoreProduct-CardContainer1">
              <div className="CoreProduct-font3">INTERACTIVE PRODUCT</div>
              <div className="Products">
                <div className="Product">
                  <div>
                    <img src={Tabletop} className="ProductIcon" />
                  </div>
                  <div>Tabletop</div>
                </div>
                <div className="Product">
                  <div>
                    <img src={VirtualTour} className="ProductIcon" />
                  </div>
                  <div>Virtual Tour</div>
                </div>
                <div className="Product-last">
                  <div>
                    <img src={Walkthrough} className="ProductIcon" />
                  </div>
                  <div>Walkthrough</div>
                </div>
              </div>

              <div className="RectangleBox" onClick={handleShow}>
                Details
              </div>
            </div>
            <Collapse in={expandmb}>
              <div className="uselesshhit">
                <div className="feature-background2">
                  <div className="feature-container2">
                    <div className="feature-font3">
                      <div> FEATURE </div>
                    </div>

                    <div className="feature-font4">
                      {data[index].text1}
                      <br />
                      <br />
                      {data[index].text2}
                      {data[index].text3 !== "" && (
                        <>
                          <br />
                          <br />
                          {data[index].text3}
                          <br />
                          <br />
                        </>
                      )}
                      {data[index].text4}
                    </div>
                    <div className="Dock-X" onClick={handleClose}>
                      <img src={Cross} className="CrossIcon2" />
                    </div>
                  </div>
                </div>{" "}
              </div>
            </Collapse>
            <div className="CoreProduct-CardContainer2">
              <div className="CoreProduct-font3">NON-INTERACTIVE PRODUCT</div>
              <div className="Products">
                <div className="Product">
                  <div>
                    <img src={Animation} className="ProductIcon" />
                  </div>
                  <div>Animation</div>
                </div>

                <div className="Product-last">
                  <div>
                    <img src={Render} className="ProductIcon" />
                  </div>
                  <div>Rendering</div>
                </div>
              </div>

              <div className="RectangleBox" onClick={handleShow1}>
                Details
              </div>
            </div>
          </div>
        </div>
      </div>
      <Collapse in={expand} timeout={duration}>
        <div>
          <div className="feature-background">
            <div className="feature-container">
              <div className="feature-font1">
                <div> FEATURE </div>
              </div>

              <div className="feature-font2">
                {data[index].text1}
                <br />
                <br />
                {data[index].text2}
                {data[index].text3 !== "" && (
                  <>
                    <br />
                    <br />
                    {data[index].text3}
                    <br />
                    <br />
                  </>
                )}
                {data[index].text4}
              </div>
              <div className="Dock-X" onClick={handleClose}>
                <img src={Cross} className="CrossIcon2" />
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </section>
  )
}
export default CoreProduct
