import React, { useState, useRef, useEffect } from "react"

import Mapleton from "../images/CaseStudies/Mapleton/render01.jpg"
import Oshidori from "../images/CaseStudies/Oshidori/oshidori_cover2.png"
import { motion, useAnimation } from "framer-motion"
import Slider from "react-slick"
import { useInView } from "react-intersection-observer"

import CaseStudiesDetail from "../components/CaseStudiesDetails.component"
import "react-slideshow-image/dist/styles.css"
import NextButton2 from "../images/CaseStudies/icons_altair-01.svg"
import Modal from "react-bootstrap/Modal"
import Enter from "../images/CaseStudies/icons_altair-02.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"

const settings = {
  dots: false,
  slidesToShow: 1,
  speed: 400,
  arrows: false,
  slidesToScroll: 1,
  adaptiveHeight: true,
  infinite: true,
  useTransform: false,
}

const CaseStudies = () => {
  const slideRef = useRef()

  const [isLayer, SetisLayer] = useState(false)
  const [mousedownPos, setmousedownPos] = useState({
    mousedownPosX: 0,
    mousedownPosY: 0,
  })
  const [mousedownPosY, setmousedownPosY] = useState()

  const [show, setShow] = useState(false)
  const [index, setIndex] = useState("")

  const handleClose = () => setShow(false)

  const handleShow1 = e => {
    if (
      mousedownPos.mousedownPosX !== e.clientX ||
      mousedownPos.mousedownPosY !== e.clientY
    ) {
    } else {
      setShow(true)
      SetisLayer(false)
      setIndex("0")
    }
  }
  const handleShow2 = e => {
    if (
      mousedownPos.mousedownPosX !== e.clientX ||
      mousedownPos.mousedownPosY !== e.clientY
    ) {
    } else {
      setShow(true)
      SetisLayer(false)

      setIndex("1")
    }
  }

  const handleShow3 = () => {
    SetisLayer(true)
    setShow(false)

    if (index == 0) setIndex("1")
    else setIndex("0")
    setTimeout(() => {
      setShow(true)
    }, 200)
    setTimeout(() => {
      SetisLayer(false)
    }, 340)
  }
  const [fullscreen, setFullscreen] = useState(true)
  const next = () => {
    slideRef.current.slickNext()
  }

  const handleOnMouseDown = e => {
    setmousedownPos({
      mousedownPosX: e.clientX,
      mousedownPosY: e.clientY,
    })
  }

  const handleOnClick = e => {
    e.stopPropagation()
    if (
      mousedownPos.mousedownPosX !== e.clientX ||
      mousedownPos.mousedownPosY !== e.clientY
    ) {
      // prevent link click if the element was dragged
      e.preventDefault()
    }
    if (
      mousedownPos.mousedownPosX === e.clientX ||
      mousedownPos.mousedownPosY === e.clientY
    ) {
      // prevent link click if the element was dragged
      alert("ok")
    }
  }

  const [ref, inView] = useInView({
    rootMargin: "-25%",
    triggerOnce: true,
  })

  const extendVariant = {
    shrunk: { opacity: 0, y: 200, transition: { type: "spring" } },
    extended: {
      opacity: 1,
      y: 0,
      transition: { type: "spring" },
    },
  }

  const extendVariant2 = {
    shrunk: { opacity: 0, y: 200, transition: { delay: 0.5, type: "spring" } },
    extended: {
      opacity: 1,
      y: 0,
      transition: { delay: 0.5, type: "spring" },
    },
  }
  return (
    <section id="CaseStudies">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        fullscreen={fullscreen}
        dialogClassName="modal-90w"
      >
        <CaseStudiesDetail
          handleClose={handleClose}
          Index={index}
          next2={handleShow3}
        />
      </Modal>

      <div className="Casestud-bigbackground">
        {isLayer && <div className="Casestud-layer"></div>}
        <div className="Casestud-background">
          <div className="Casestud-Container">
            <div className="Casestud">CASE STUDIES</div>
          </div>
        </div>
        <motion.div className="Casestud-slide">
          <Slider {...settings} ref={slider => (slideRef.current = slider)}>
            <motion.div className="each-slide">
              <motion.div>
                <LazyLoadImage
                  effect="blur"
                  placeholderSrc={Mapleton}
                  alt={Mapleton}
                  onMouseDown={e => handleOnMouseDown(e)}
                  onClick={e => handleShow1(e)}
                  src={Mapleton}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "calc(100vh - 140px)",
                  }}
                  className="Casestud-back"
                />
              </motion.div>
              <motion.div
                className="textblock"
                onMouseDown={e => handleOnMouseDown(e)}
                onClick={e => handleShow1(e)}
              >
                <div>
                  <div className="CaseStudies-Title">/ LUXURY RESIDENCE</div>
                  <div className="CaseStudies-Content">
                    MAPLETON, <br /> LOS ANGELES
                  </div>
                </div>

                <div className="textblock-img">
                  <img src={Enter} className="CaseStudies-enter" />
                </div>
              </motion.div>

              <div className="textblock2">
                <div
                  className="textblock-text"
                  onMouseDown={e => handleOnMouseDown(e)}
                  onClick={e => handleShow1(e)}
                >
                  <div className="CaseStudies-Title"></div>
                  <div className="CaseStudies-Content"></div>
                </div>

                <div onClick={next}>
                  <img
                    src={NextButton2}
                    onClick={next}
                    className="CaseStudies-enter"
                  />{" "}
                </div>
              </div>
            </motion.div>
            <motion.div className="each-slide">
              <motion.div>
                <img
                  onMouseDown={e => handleOnMouseDown(e)}
                  onClick={e => handleShow2(e)}
                  src={Oshidori}
                  className="Casestud-back"
                />
              </motion.div>
              <motion.div
                className="textblock"
                onMouseDown={e => handleOnMouseDown(e)}
                onClick={e => handleShow2(e)}
              >
                <div>
                  <div className="CaseStudies-Title">/ INTEGRATED RESORT</div>
                  <div className="CaseStudies-Content">
                    JAPAN <br />
                  </div>
                </div>
                <div>
                  <img src={Enter} className="CaseStudies-enter" />
                </div>
              </motion.div>
              <div className="textblock2">
                <div
                  className="textblock-text"
                  onMouseDown={e => handleOnMouseDown(e)}
                  onClick={e => handleShow2(e)}
                >
                  <div className="CaseStudies-Title"></div>
                  <div className="CaseStudies-Content"></div>
                </div>

                <div onClick={next}>
                  <img
                    src={NextButton2}
                    onClick={next}
                    className="CaseStudies-enter"
                  />{" "}
                </div>
              </div>
            </motion.div>
          </Slider>
        </motion.div>
      </div>
    </section>
  )
}
export default CaseStudies
