import React, { useRef, useState } from "react"

import TouchDevice from "../images/Platform/Touch_1.png"
import VR from "../images/Platform/VR_1.png"
import AR from "../images/Platform/AR_1.png"
import Slider from "react-slick"

const devices = [
  {
    device: "TOUCH DEVICE",
    desc: "Smartphones, large touch panels and/or tablets based on touch.",
  },
  {
    device: "VR HEADSET",
    desc:
      "Virtual reality equipment with or without hand controllers or accesories.",
  },
  {
    device: "AUGMENTED REALITY",
    desc: "Augmented Reality experiences on software compatible devices.",
  },
]
const Platform = () => {
  const [index, setIndex] = useState("0")
  const slideRef = useRef()

  const settings = {
    dots: false,
    slidesToShow: 1,
    speed: 400,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: current => setIndex(current.toString()),
    infinite: true,
    arrows: false,
    centerMode: true,
    useTransform: false,
    centerPadding: "16%",
    responsive: [
      {
        breakpoint: 400,
        settings: {
          centerPadding: "15%",
        },
      },
      {
        breakpoint: 340,
        settings: {
          centerPadding: "13%",
        },
      },
    ],
  }

  return (
    <section id="Platform">
      <div className="Platform-background">
        <div className="Title-black2">PLATFORM</div>
        <div className="Platform-mobile">
          <Slider ref={slider => (slideRef.current = slider)} {...settings}>
            <div className="test">
              <div>
                <img src={TouchDevice} className="device-mobile" />
              </div>
            </div>
            <div className="test">
              <div>
                <img src={VR} className="device-mobile " />
              </div>
            </div>
            <div className="test">
              <div>
                <img src={AR} className="device-mobile" />
              </div>
            </div>
          </Slider>

          <div className="Platform-font3">{devices[index].device}</div>
          <div className="Platform-font2">{devices[index].desc}</div>
        </div>

        <div className="Platform-devices">
          <div className="Platform-device" id="elem1">
            <img src={TouchDevice} />
            <div className="Platform-DescBox" id="elem2">
              <div className="Platform-font3">TOUCH DEVICES</div>
              <div className="Platform-font2 ">
                Smartphones, large touch panels and/or tablets based on touch.
              </div>
            </div>
          </div>
          <div className="Platform-device">
            <img src={VR} />
            <div className="Platform-DescBox">
              <div className="Platform-font3">VR HEADSET</div>
              <div className="Platform-font2">
                Virtual reality equipment with or without hand controllers or
                accessories.
              </div>
            </div>
          </div>
          <div className="Platform-device">
            <img src={AR} />
            <div className="Platform-DescBox">
              <div className="Platform-font3">AUGMENTED REALITY</div>
              <div className="Platform-font2">
                Augmented Reality experiences on software compatible devices.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Platform
