import React, { useRef, useState, useEffect, useCallback } from "react"
import Burger from "../../images/burger.png"
import * as Scroll from "react-scroll"

const Link2 = Scroll.Link
const activeStyles = {
  background: "red",
}
class Navs extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  constructor(props) {
    super(props)
    this.state = {
      positionIdx: 0,
      dimModeIdx: 0,
      isVisible: false,
      fluid: true,
      customAnimation: false,
      slow: false,
      size: 1,
      mode: "Wrapper",
      img: Burger,
      pressed: false,
    }
  }

  render() {
    return (
      <nav>
        <div className="NavsContainer">
          <div>
            <Link2
              className="Navs-pre"
              activeClass="active"
              to="AboutUs"
              spy={true}
              smooth={true}
            >
              <pre>
                {`ABOUT 
US
`}
              </pre>
            </Link2>
          </div>
          <div class="vl"></div>
          <div>
            <Link2
              className="Navs-pre"
              activeClass="active"
              to="WhatWeDo"
              spy={true}
            >
              <pre>
                {`WHAT WE
DO
`}
              </pre>
            </Link2>
          </div>
          <div class="vl"></div>
          <div>
            <Link2
              className="Navs-pre"
              activeClass="active"
              to="HowWeDo"
              spy={true}
            >
              <pre>{`HOW WE
DO
`}</pre>
            </Link2>
          </div>
          <div class="vl"></div>
          <div>
            <Link2 className="Navs-pre" to="CaseStudies" spy={true}>
              <pre>{`CASE
STUDIES
`}</pre>
            </Link2>
          </div>
          <div class="vl"></div>
          <div>
            <Link2
              className="Navs-pre"
              activeClass="active2"
              to="Platform"
              spy={true}
              id="foo5"
            >
              <pre>{`PLATFORM
`}</pre>
            </Link2>
          </div>
          <div class="vl"></div>
          <div>
            <Link2
              className="Navs-pre"
              activeClass="active"
              to="ContactUs"
              id="foo4"
              spy={true}
            >
              <pre>{`CONTACT
`}</pre>
            </Link2>
          </div>
          <div className="Navs-space"></div>
          <div className="Navs-font">
            <a className="Navs-pre" href="/gallery">
              GALLERY
            </a>
          </div>
        </div>
      </nav>
    )
  }
}
export default Navs
