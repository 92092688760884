import React from "react"
import Burger from "../../images/burger.png"
import Cross from "../../images/Cross.svg"
import Navs from "../UI/Navs.component"
import Linkedin from "../../images/linkedin.png"
import Vimeo from "../../images/vimeo.png"
var timer1
class Sidenav extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  constructor(props) {
    super(props)
    this.state = {
      positionIdx: 0,
      dimModeIdx: 0,
      isVisible: false,
      fluid: true,
      customAnimation: false,
      slow: false,
      size: 1,
      mode: "Wrapper",
      img: Burger,
      pressed: false,
      MenuVisible: false,
    }
  }

  Changepath = () => {
    if (!this.state.pressed) {
      this.setState({ mode: "Wrapper" })
      this.setState({ img: Cross })
      this.setState({ pressed: true })
      timer1 = setTimeout(() => this.setState({ MenuVisible: true }), 200)

      document.getElementById("foo").style.transition =
        "clip-path 0.4s ,width 0.4s ,EaseInOut"
      document.getElementById("foo").style.clipPath =
        "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"
      document.getElementById("foo").style.width = "300px"
    } else {
      document.getElementById("foo").style.width = "150px"
      document.getElementById("foo").style.clipPath =
        "polygon(0 8%, 100% 0, 100% 100%, 0% 100%)"
      clearTimeout(timer1)
      this.setState({ img: Burger })
      this.setState({ pressed: false })
      this.setState({ MenuVisible: false })
      setTimeout(() => this.setState({ MenuVisible: false }), 200)
    }
  }

  render() {
    return (
      <div className="sidenavbar">
        <div id="foo" className="Wrapper">
          <img
            src={this.state.img}
            className="SidemenuButton"
            onClick={this.Changepath}
          />

          {this.state.MenuVisible && (
            <div className="Sidenav">
              <Navs />
            </div>
          )}

          {!this.state.MenuVisible && (
            <div className="SideNav-Community">
              {/* <div>
<img src={Fb}  />
</div> */}
              <div>
                <a
                  href="https://www.linkedin.com/company/altairstudios/"
                  target="_blank"
                >
                  <img src={Linkedin} className="community-img" />
                </a>
              </div>
              {/* <div>
<img src={Ig}  />
</div> */}
              <div>
                <a href="https://vimeo.com/altairstudio" target="_blank">
                  <img src={Vimeo} className="community-img" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default Sidenav
