import React from "react"

import cover_LR from "../images/luxury-residence/LR_01.webp"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import TouchDevice from "../images/Platform/touch_device.svg"
import VR from "../images/Platform/vr_headset.svg"
import AR from "../images/Platform/ar.svg"

import Altair_logo from "../images/altair-logo-white.svg"
import Fb from "../images/fb.png"
import Linkedin from "../images/linkedin.png"
import Ig from "../images/ig.png"
import Vimeo from "../images/vimeo.png"

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      positionIdx: 0,
      dimModeIdx: 0,
      isVisible: false,
      fluid: true,
      customAnimation: false,
      slow: false,
      size: 1,
    }
  }

  render() {
    return (
      <section id="ContactUs" className="ContactUsSection">
        <div className="ContactUs-Background">
          <div className="ContactUs-mobile">
            <img src={Altair_logo} className="ContactUs-Logo-mobile" />
          </div>
          <div className="ContactUs-Container">
            <div className="ContactUs-Container2">
              <div className="ContactUs-font1">CONTACT US</div>
              <div className="ContactUs-font2">
                <a href="mailto:info@altair-studio.com">
                  info@altair-studio.com
                </a>
              </div>

              <div className="ContactUs-AddressBox">
                <div className="ContactUs-Box1">
                  <div className="ContactUs-font3">HONG KONG</div>

                  <div className="ContactUs-font4">
                    <pre>
                      {`
9/F, E-trade Plaza
24 Lee Chung street, Chai Wan
Hong Kong
`}
                    </pre>
                  </div>
                </div>
                <div className="ContactUs-space"></div>
                <div>
                  <div className="ContactUs-font3">LOS ANGELES</div>

                  <div className="ContactUs-font4">
                    <pre>
                      {`
141 S Robertson Blvd, Suite 201
Los Angeles, CA 90048
`}
                    </pre>
                  </div>
                </div>
              </div>
            </div>
            <div className="ContactUs-SocialMedia">
              <img src={Altair_logo} className="ContactUs-Logo" />

              <div className="ContactUs-Community">
                <div>
                  <a
                    href="https://www.linkedin.com/company/altairstudios/"
                    target="_blank"
                  >
                    <img src={Linkedin} className="community-img" />
                  </a>
                </div>

                <div>
                  <a href="https://vimeo.com/altairstudio" target="_blank">
                    <img src={Vimeo} className="community-img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default ContactUs
