import React, { useState } from "react"

import { useInView } from "react-intersection-observer"

const SelfIntro = () => {
  const [ref, inView] = useInView({
    rootMargin: "-25%",
    triggerOnce: true,
  })

  const extendVariant = {
    shrunk: { opacity: 0 },
    extended: {
      opacity: 1,
    },
  }
  const [open, setOpen] = useState(false)

  return (
    <section id="AboutUs" ref={ref}>
      <div className="self-background">
        <div className="self-intro-container">
          <div className="Self-Intro-font2 Self-title">
            Using immersive technologies, we craft experiences that extend
            across the senses and bring projects to life.
          </div>

          <div className="Self-Intro-font2">
            Here at Altair Studio, we believe that within every project there is
            a core idea or feeling that is trying to be communicated.
            <br /> <br />
            During planning and execution it is difficult to effectively
            communicate these core concepts, often being lost within many
            technical documents until project completion and able to be directly
            experienced. What we do is bring those ideas back out into the open
            for all to see, making the abstract tangible and communicating these
            often hidden ideas through interaction and visualization.
          </div>
          <div className="Self-Intro-font2 ">
            Engage your audience with immersive interactive experiences and
            stunning visuals, bringing out the very best your project has to
            offer.
            <br /> <br />
            From virtual tours of luxury retail and urban projects to product
            configurators and simulations, we explore all possibilities to
            create new worlds where ideas are felt and memories are lifetime
            souvenirs.
          </div>
        </div>
      </div>
    </section>
  )
}

export default SelfIntro
