import React from "react"

const Legal = () => {
  return (
    <div className="LegalContainer">
      <div>© 2021 Altair Studio Ltd. All rights reserved.</div>
    </div>
  )
}

export default Legal
