import React, { useRef, useState, useEffect, useCallback } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Video from "../components/UI/Video.component"
import Slider from "react-slick"
import Cross from "../images/Cross.png"
import JSONData from "../content/content.json"
import Lightbox from "react-image-lightbox"
import { LazyLoadImage } from "react-lazy-load-image-component"
import NextButton2 from "../images/rightarrow.png"
import NextButton3 from "../images/rightarrow2.png"

import { Next } from "react-bootstrap/esm/PageItem"
import { NoToneMapping } from "three"

const SHOWREEL_URL =
  "https://player.vimeo.com/external/557038760.hd.mp4?s=187fd9cd7a085a82a58fd8257061146c69981665&profile_id=169"

const settings = {
  infinite: true,
  dots: true,
  adaptiveHeight: true,
  slidesToShow: 1,
  centerMode: true,
  arrows: true,
  centerPadding: "15%",
  nextArrow: <SampleNextArrow />,

  responsive: [
    {
      breakpoint: 1440,
      settings: {
        centerPadding: "15%",
      },
    },
  ],
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        Opacity: 0,
      }}
      onClick={onClick}
    >
      <img src={NextButton3} className="rendering-next2" />
    </div>
  )
}
const dataSet = [JSONData.mapleton, JSONData.oshidori]

const CaseStudiesDetails = ({ handleClose, Index, next2 }) => {
  const sceneRef = useRef()
  const [photoIndex, setphotoIndex] = useState(0)
  const [isOpen, setisOpen] = useState(false)
  const componentDidMount = () => {
    sceneRef.scrollTo(0, 0)
  }
  const [mousedownPos, setmousedownPos] = useState({
    mousedownPosX: 0,
    mousedownPosY: 0,
  })
  const [index, setIndex] = useState(Index)

  const switchIndex = () => {
    sceneRef.current.scrollTo(0, 0)

    if (index === 0) {
      setIndex(1)
    } else setIndex(0)
  }
  const OpenLightbox = (e, i) => {
    if (
      mousedownPos.mousedownPosX !== e.clientX ||
      mousedownPos.mousedownPosY !== e.clientY
    ) {
    } else {
      setphotoIndex(i)
      setisOpen(true)
    }
  }

  const handleOnMouseDown = e => {
    setmousedownPos({
      mousedownPosX: e.clientX,
      mousedownPosY: e.clientY,
    })
  }
  const next = () => {
    slideRef.current.slickNext()
  }
  const videoRef = useRef()
  const slideRef = useRef()

  useEffect(() => {
    videoRef.current.defaultMuted = true
  })
  return (
    <div className="Details-Background">
      <div className="Details-Container">
        {isOpen && (
          <Lightbox
            mainSrc={dataSet[index].images[photoIndex]}
            nextSrc={
              dataSet[index].images[
                (photoIndex + 1) % dataSet[index].images.length
              ]
            }
            prevSrc={
              dataSet[index].images[
                (photoIndex + dataSet[index].images.length - 1) %
                  dataSet[index].images.length
              ]
            }
            onCloseRequest={() => setisOpen(false)}
            onMovePrevRequest={() =>
              setphotoIndex(
                (photoIndex + dataSet[index].images.length - 1) %
                  dataSet[index].images.length
              )
            }
            onMoveNextRequest={() =>
              setphotoIndex((photoIndex + 1) % dataSet[index].images.length)
            }
          />
        )}
        <div className="Details-About" ref={sceneRef}>
          <div className="Details-Desc">
            <div className="Details-font3">{dataSet[index].title}</div>
            <div className="Details-font4">{dataSet[index].location}</div>
            <div className="Details-space"></div>

            <div className="Details-font1">ABOUT THE PROJECT</div>
            <div className="Details-font2">{dataSet[index].atp}</div>
            <div className="Details-space"></div>
            <div className="Details-font1">OUR APPROACH</div>
            <div className="Details-font2">
              {dataSet[index].approach} <br />
              <br /> {dataSet[index].approach2}
            </div>
          </div>
          <div>
            <Container className={"Details-contain" + (index === 1 ? "1" : "")}>
              <div className="Details-font1">SOLUTIONS</div>
              <Row className="Details-Row">
                <Col className="Details-Col">
                  <div className="Details-RecBorder">Tabletop</div>
                </Col>
                <Col className="Details-Col">
                  <div className="Details-RecBorder">Walkthrough</div>
                </Col>
              </Row>
              <Row className="Details-Row">
                <Col className="Details-Col">
                  <div className="Details-RecBorder">Virtual Tour</div>
                </Col>
                <Col className="Details-Col">
                  <div className="Details-RecBorder">Animation</div>
                </Col>
              </Row>
              <Row className="Details-Row">
                <Col>
                  <div className="Details-RecBorder">Rendering</div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        {/*End about */}
        <Video
          videoRef={videoRef}
          videoSrcURL={dataSet[index].SHOWREEL_URL}
          videoTitle="Showreel"
          className="proj-thumbnail"
        />

        <div className="Details-Slides">
          <Slider {...settings} ref={slider => (slideRef.current = slider)}>
            {dataSet[index].images.map((image, i) => {
              return (
                <div
                  className="Details-Slide"
                  onClick={e => OpenLightbox(e, i)}
                  onMouseDown={e => handleOnMouseDown(e)}
                >
                  <LazyLoadImage
                    effect="blur"
                    src={image}
                    placeholderSrc={<h1>loading...</h1>}
                    placeholder={<h1>test</h1>}
                    visibleByDefault={true}
                    className="render01"
                  />{" "}
                </div>
              )
            })}
          </Slider>
        </div>

        <div className="Dock-X2">
          <img src={Cross} className="CrossIcon" onClick={handleClose} />
          <div className="Details-nextproj" onClick={next2}>
            Next Project >
          </div>
        </div>
      </div>
    </div>
  )
}
export default CaseStudiesDetails
