import React, { useState, useRef, useEffect } from "react"

import Downarrow from "../images/downarrow.png"
import { Link } from "react-scroll"
import playbutton from "../images/play-button.svg"
import { AnimatePresence, motion } from "framer-motion"
import Cross from "../images/Cross.svg"
import VideoPoster from "../images/video-poster.jpg"
import { fadeTransition, fadeVariant } from "./animations"
const SHOWREEL_URL =
  "https://player.vimeo.com/progressive_redirect/playback/647226027/rendition/720p/file.mp4?loc=external&signature=a00e38f2616ded813d7e5b837cae388b23912ea1e2a130b3dcf01891999c7d76"
const SHOWREEL_URL2 =
  "https://player.vimeo.com/progressive_redirect/playback/651388869/rendition/1080p/file.mp4?loc=external&signature=2276c82daf4b9116e90cb785a1ce4d9040b06c406375957766f67fa439570181"
const Cover = () => {
  const [showreelActive, setShowreelActive] = useState(false)
  const videoRef = useRef()
  const vidRef = useRef()
  const PlayShowreel = () => {
    setShowreelActive(true)
    setTimeout(() => {
      videoRef.current.play()
    }, 200)
  }

  const CloseShowreel = () => {
    setShowreelActive(false)
  }

  useEffect(() => {
    vidRef.current.defaultMuted = true
    vidRef.current.play()
  })
  return (
    <section id="AboutUs">
      {showreelActive && (
        <motion.div
          variants={fadeVariant}
          initial="fadeOut"
          animate="faded"
          exit="fadeOut"
          transition={fadeTransition.quick}
          className="video-modal"
        >
          <img
            src={Cross}
            alt="Close button"
            className="close"
            onClick={CloseShowreel}
          />
          <div className="video-container">
            <video
              ref={videoRef}
              src={SHOWREEL_URL2}
              poster={VideoPoster}
              preload="auto"
              autoplay="autoplay"
              loop
              playsInline
              controls
              controlsList="nodownload"
            />
          </div>
        </motion.div>
      )}
      <div className="outter">
        <div className="cover-video-container">
          <div className="proj-gameplay">
            <video
              ref={vidRef}
              src={SHOWREEL_URL}
              title="Showreel"
              preload="auto"
              autoPlay
              loop
              muted
              controlsList="nodownload"
              playsInline
              width="100%"
              height="100%"
              className="cover-video"
            />
          </div>
          <div class="callout">
            <div className="Self_div">
              <div className="Self_title">EXTEND</div>
              <div>ACROSS</div>
              <div className="text1">THE SENSES</div>
              <div className="text2">THE</div>
              <div className="text2">SENSES</div>
              <div className="text3" onClick={PlayShowreel}>
                <div className="showreel-container">
                  <img src={playbutton} className="cover-play" />
                  <div className="showreel-text"> &nbsp;SHOWREEL</div>
                </div>
              </div>
              <div>
                <Link to="AboutUs" smooth={true}>
                  <motion.img
                    src={Downarrow}
                    animate={{ y: [0, -10, 0] }}
                    transition={{ duration: 1, repeat: Infinity }}
                    className="self_intro_downarrow"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cover
