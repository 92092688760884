import React, { useRef, useState } from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Flow from "../images/GeneralFlow/Flow@2x.png"
import Flow2 from "../images/GeneralFlow/Flow2_1.png"
import Flow3 from "../images/GeneralFlow/Flow3_1.png"
import Flow4 from "../images/GeneralFlow/Flow4_1.png"
import Flow5 from "../images/GeneralFlow/Flow5_1.png"
import Tabletop from "../images/GeneralFlow/tabletop.svg"
import VirtualTour from "../images/GeneralFlow/virtualtour.svg"
import Walkthrough from "../images/GeneralFlow/walkthrough.svg"
import Animation from "../images/GeneralFlow/Animation.svg"
import Render from "../images/GeneralFlow/render.svg"
import Slider from "react-slick"
import { LazyLoadImage } from "react-lazy-load-image-component"

const data = [
  {
    id: "0",
    key: "0",
    title: "CONTEXT",
    text: "Area and location selling points are introduced.",
    img: Flow,
    className: "Flow",
  },
  {
    id: "1",
    key: "1",
    title: "OVERALL SITE",
    text:
      "Tabletop zooms to model of the overall site, with key exterior features marked.",
    img: Flow2,
    className: "Flow",
  },
  {
    id: "2",
    key: "2",
    title: "BUILDING VIEW",
    text:
      "In the case where there are multiple structures, one can be selected and inspected.",
    img: Flow3,
    className: "Flow",
  },
  {
    id: "3",
    key: "3",
    title: "FLOOR BREAKDOWN",
    text:
      "Building expands into exploded view showing individual floors, allowing for the inspection of each in detail.",
    img: Flow4,
    className: "Flow",
  },
  {
    id: "4",
    key: "4",
    title: "ROOM DETAILS",
    text:
      "Info for key rooms or interior features shown, allowing for transition into a Virtual Tour.",
    img: Flow5,
    className: "Flow",
  },
]

const options = [
  {
    id: 0,
    Content: "0",
    className: "OptionBox",
  },
  {
    id: 1,
    Content: "1",
    className: "OptionBox",
  },
  {
    id: 2,
    Content: "2",
    className: "OptionBox",
  },
  {
    id: 3,
    Content: "3",
    className: "OptionBox",
  },
  {
    id: 4,
    Content: "4",
    className: "OptionBox",
  },
]
const Testing = () => {
  const slideRef = useRef()
  const optionRef = useRef()
  const [index, setIndex] = useState("0")
  const [optionindex, setOptionIndex] = useState("0")
  const [toggle, setToggle] = useState()
  const [toggleable, setToggleable] = useState(true)
  const [optiontoggleable, setOptionToggleable] = useState(true)

  const [visible, setVisible] = useState(true)
  const settings = {
    dots: false,
    slidesToShow: 1,
    speed: 400,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: current => setIndex(current.toString()),
    arrows: false,
    infinite: true,
    useTransform: false,
  }

  const settings2 = {
    dots: false,
    slidesToShow: 1,
    speed: 400,
    slidesToScroll: 1,
    adaptiveHeight: true,
    afterChange: current => setOptionIndex(current.toString()),
    arrows: false,
    infinite: true,
    useTransform: false,
  }
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    autoplay: false,
    arrows: false,
    indicators: i => (
      <div className="Flow">
        <div className="oval"></div>
        <div className="FlowTitle">{data[i].title}</div>
        <div className="FlowDetails">{data[i].text}</div>
      </div>
    ),
  }

  const next = () => {
    slideRef.current.slickGoTo(0)
  }

  const handleClick = index => {
    if (toggleable) {
      setIndex(index)
      slideRef.current.slickGoTo(index)
      setToggleable(false)
      setTimeout(() => {
        setToggleable(true)
      }, 500)
    }
  }

  const handleOptionClick = optionindex => {
    if (optiontoggleable) {
      setOptionIndex(optionindex)
      optionRef.current.slickGoTo(optionindex)
      setOptionToggleable(false)
      setTimeout(() => {
        setOptionToggleable(true)
      }, 500)
    }
  }

  return (
    <div className="test-background">
      <div className="SubTitle-black">/ HOW WE DO</div>
      <div className="Title-black">GENERAL FLOW</div>

      <div className="slideref2">
        <Slider ref={slider => (slideRef.current = slider)} {...settings}>
          <div className="test">
            <div>
              <LazyLoadImage
                placeholderSrc={data[0].img}
                alt={data[0].img}
                effect="blur"
                src={data[0].img}
                className="img1"
                visibleByDefault={true}
              />
            </div>
          </div>
          <div className="test">
            <div>
              <LazyLoadImage effect="blur" src={data[1].img} className="img2" />{" "}
            </div>
          </div>
          <div className="test">
            <div>
              <LazyLoadImage effect="blur" src={data[2].img} className="img2" />{" "}
            </div>
          </div>
          <div className="test">
            <div>
              <LazyLoadImage effect="blur" src={data[3].img} className="img2" />{" "}
            </div>
          </div>
          <div className="test">
            <div>
              <LazyLoadImage effect="blur" src={data[4].img} className="img2" />{" "}
            </div>
          </div>
        </Slider>
      </div>
      <div className="FlowsContainer2">
        <div className="Flowsline"></div>
        {data.map(dataa => {
          return (
            <div
              onClick={() => handleClick(dataa.key)}
              className={
                dataa.className + (dataa.key === index ? "-active_item" : "")
              }
            >
              <div className="hrcontainer">
                <div id="outer-circle">
                  <div id="inner-circle"></div>
                </div>
              </div>
              <div className="FlowTextContainer">
                <div className="FlowTitle">{data[dataa.key].title}</div>
                <div className="FlowDetails">{data[dataa.key].text}</div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="SubTitle-black top-space-mb">/ HOW WE DO</div>
      <div className="Title-black">TAILORED SOLUTIONS</div>
      <div className="Title-black ">
        <div className="GeneralFlow-text">
          We can now offer a tailor made solution that truly meets your
          project's needs. Having a clear set of standard deliverables means you
          can choose the features that best tell the story of your project.
        </div>
      </div>
      <div className="SubTitle-black Tailor-Container3">
        <div className="Tailor-Column">
          <div className="Tailored_number">1</div>
          <div className="Tailored_method">
            <img src={Animation} />
            <div>Animation</div>
          </div>
          <div className="Tailored_method2"></div>
          <div className="Tailored_method2"></div>

          <div className="Tailored_method2"></div>

          <div className="Tailored_method2"></div>
        </div>
        <div className="Tailor-Column">
          <div className="Tailored_number">2</div>
          <div className="Tailored_method">
            <img src={Animation} />
            <div>Animation</div>
          </div>
          <div className="Tailored_method">
            <img src={Render} />
            <div>Rendering</div>
          </div>
          <div className="Tailored_method2"></div>
          <div className="Tailored_method2"></div>

          <div className="Tailored_method2"></div>
        </div>

        <div className="Tailor-Column">
          <div className="Tailored_number">3</div>
          <div className="Tailored_method">
            <img src={VirtualTour} />
            <div>Virtual Tour</div>
          </div>
          <div className="Tailored_method">
            <img src={Animation} />
            <div>Animation</div>
          </div>
          <div className="Tailored_method">
            <img src={Render} />
            <div>Rendering</div>
          </div>
          <div className="Tailored_method2"></div>

          <div className="Tailored_method2"></div>
        </div>
        <div className="Tailor-Column">
          <div className="Tailored_number">4</div>
          <div className="Tailored_method">
            <img src={Tabletop} />
            <div>Tabletop</div>
          </div>
          <div className="Tailored_method">
            <img src={Animation} />
            <div>Animation</div>
          </div>
          <div className="Tailored_method">
            <img src={Render} />
            <div>Rendering</div>
          </div>
          <div className="Tailored_method2"></div>

          <div className="Tailored_method2"></div>
        </div>
        <div className="Tailor-Column">
          <div className="Tailored_number">5</div>
          <div className="Tailored_method">
            <img src={Tabletop} />
            <div>Tabletop</div>
          </div>
          <div className="Tailored_method">
            <img src={Walkthrough} />
            <div>Walkthrough</div>
          </div>
          <div className="Tailored_method">
            <img src={VirtualTour} />
            <div>Virtual Tour</div>
          </div>
          <div className="Tailored_method">
            <img src={Animation} />
            <div>Animation</div>
          </div>
          <div className="Tailored_method">
            <img src={Render} />
            <div>Rendering</div>
          </div>
        </div>
      </div>
      {/*<div className="Title-black">
        <div className="Tailor-Container2">
          <div className="TailorBox">
            <div className="TailorNumber">
              <div className="TailorTest">1</div>
            </div>
            <div className="TailorMethod">
              <img src={Animation} className="TailoredIcon" />
              <div>Animation</div>
            </div>
          </div>

          <div className="TailorBox">
            <div className="TailorNumber">
              <div className="TailorTest">2</div>
            </div>
            <div className="TailorMethod">
              <img src={Animation} className="TailoredIcon" />
              <div>Animation</div>
              <img src={Render} className="TailoredIcon" />
              <div>Rendering</div>
            </div>
          </div>
          <div className="TailorBox">
            <div className="TailorNumber">
              <div className="TailorTest">3</div>
            </div>
            <div className="TailorMethod">
              <img src={VirtualTour} className="TailoredIcon" />
              <div>Virtual Tour</div>
              <img src={Animation} className="TailoredIcon" />
              <div>Animation</div>
              <img src={Render} className="TailoredIcon" />
              <div>Rendering</div>
            </div>
          </div>
          <div className="TailorBox">
            <div className="TailorNumber">
              <div className="TailorTest">4</div>
            </div>
            <div className="TailorMethod">
              <img src={Tabletop} className="TailoredIcon" />
              <div>Tabeletop</div>
              <img src={Animation} className="TailoredIcon" />
              <div>Animation</div>
              <img src={Render} className="TailoredIcon" />
              <div>Rendering</div>
            </div>
          </div>
          <div className="TailorBox">
            <div className="TailorNumber">
              <div className="TailorTest">5</div>
            </div>
            <div className="TailorMethod">
              <img src={Tabletop} className="TailoredIcon" />
              <div>Tabletop</div>
              <img src={Walkthrough} className="TailoredIcon" />
              <div>Walkthrough</div>
              <img src={VirtualTour} className="TailoredIcon" />
              <div>Virtual Tour</div>
              <img src={Animation} className="TailoredIcon" />
              <div>Animation</div>
              <img src={Render} className="TailoredIcon" />
              <div>Rendering</div>
            </div>
          </div>
        </div> */}

      <div className="OptionContainer-mobile">
        {options.map(option => {
          return (
            <div
              onClick={() => handleOptionClick(option.id)}
              className={
                option.className +
                (option.Content === optionindex ? "-active_item" : "")
              }
            >
              <div>{option.id + 1}</div>
            </div>
          )
        })}
      </div>
      <div className="TailorContainer-mobile">
        <Slider ref={slider2 => (optionRef.current = slider2)} {...settings2}>
          <div className="TailorBox-mobile">
            <Container>
              <Row>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Animation} className="TailoredIcon-mobile" />
                    </div>
                    <div>Animation</div>
                  </div>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Container>
          </div>
          <div className="TailorBox-mobile">
            <Container>
              <Row>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Animation} className="TailoredIcon-mobile" />
                    </div>
                    <div>Animation</div>
                  </div>
                </Col>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Render} className="TailoredIcon-mobile" />
                    </div>
                    <div>Rendering</div>
                  </div>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          </div>
          <div className="TailorBox-mobile">
            <Container>
              <Row>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={VirtualTour} className="TailoredIcon-mobile" />
                    </div>
                    <div>Virtual Tour</div>
                  </div>
                </Col>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Animation} className="TailoredIcon-mobile" />
                    </div>
                    <div>Animation</div>
                  </div>
                </Col>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Render} className="TailoredIcon-mobile" />
                    </div>
                    <div>Rendering</div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="TailorBox-mobile">
            <Container>
              <Row>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Tabletop} className="TailoredIcon-mobile" />
                    </div>
                    <div>Tabletop</div>
                  </div>
                </Col>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Animation} className="TailoredIcon-mobile" />
                    </div>
                    <div>Animation</div>
                  </div>
                </Col>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Render} className="TailoredIcon-mobile" />
                    </div>
                    <div>Rendering</div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="TailorBox-mobile">
            <Container>
              <Row>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Tabletop} className="TailoredIcon-mobile" />
                    </div>
                    <div>Tabletop</div>
                  </div>
                </Col>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Walkthrough} className="TailoredIcon-mobile" />
                    </div>
                    <div>Walkthrough</div>
                  </div>
                </Col>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={VirtualTour} className="TailoredIcon-mobile" />
                    </div>
                    <div>Virtual Tour</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Animation} className="TailoredIcon-mobile" />
                    </div>
                    <div>Animation</div>
                  </div>
                </Col>
                <Col>
                  <div className="TailorItem-mobile">
                    <div>
                      <img src={Render} className="TailoredIcon-mobile" />
                    </div>
                    <div>Rendering</div>
                  </div>
                </Col>
                <Col></Col>
              </Row>
            </Container>
          </div>
        </Slider>
      </div>
    </div>
  )
}
export default Testing
