import React from "react"
import Burger from "../../images/burger.png"
import Cross from "../../images/Cross.svg"
import Navs from "../UI/Navs.component"

class Sidenav extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  constructor(props) {
    super(props)
    this.state = {
      positionIdx: 0,
      dimModeIdx: 0,
      isVisible: false,
      fluid: true,
      customAnimation: false,
      slow: false,
      size: 1,
      mode: "Wrapper",
      img: Burger,
      pressed: false,
      MenuVisible: true,
    }
  }

  Changepath = () => {
    if (!this.state.pressed) {
      this.setState({ mode: "Wrapper" })
      this.setState({ img: Cross })
      this.setState({ pressed: true })
      setTimeout(() => {
        this.setState({ MenuVisible: true })
      }, 300)
      document.getElementById("foo").style.transition = "clip-path 1s ,width 1s"
      document.getElementById("foo").style.clipPath =
        "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"
      document.getElementById("foo").style.width = "300px"
    } else {
      document.getElementById("foo").style.width = "150px"
      document.getElementById("foo").style.clipPath =
        "polygon(0 8%, 100% 0, 100% 100%, 0% 100%)"

      this.setState({ img: Burger })
      this.setState({ pressed: false })
      this.setState({ MenuVisible: false })
    }
  }

  render() {
    return (
      <div className="sidenavbar">
        <div id="foo" className="Wrapper">
          {this.state.MenuVisible && (
            <div className="Sidenav2">
              <Navs />
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default Sidenav
