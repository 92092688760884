import React from "react"
import { AnimatePresence, motion } from "framer-motion"

import AltairLogoSVG from "../../images/altair-logo-black.svg"

const LoadingScreen = ({ loading }) => (
  <AnimatePresence>
    {loading && (
      <motion.div
        exit={{
          opacity: 0,
          transition: { duration: 0.82 },
        }}
        className="loading"
      >
        <motion.img
          exit={{ opacity: 0, scale: 0.93 }}
          src={AltairLogoSVG}
          alt="Altair Logo"
          className="loading-logo"
        />
      </motion.div>
    )}
  </AnimatePresence>
)

export default LoadingScreen
